<template>
  <div class="home">
    <div class="bg-item"></div>

    <div class="about-us">
      <div class="introduce">
        <h1>关于我们</h1>
        <div style="height: 20px;"></div>
        <p><strong>武汉模鼎科技有限公司</strong>是高分子成形相关企业数字化、智能化解决方案提供商。公司为高分子相关模具、装备、制品生产等企业提供智能试模系统、智能监控系统、智能检测系统等工业软件，为院校提供智能注塑教学及实训基地等一系列智能制造解决方案，为高分子成形相关企业及从业人员提供在线与现场培训、技术咨询等服务。公司拥有授权发明专利2项，软件著作权24项，参与制定行业标准2项。</p>
      </div>
      <div style="height: 40px;"></div>
      <div class="introduce">
        <h1>团队介绍</h1>
        <div style="height: 20px;"></div>
        <p>我们的团队先后承担国家973、863、科技支持计划、重点研发计划等重要课题20多项。获国家科技进步二等奖3项、国家自然科学二等奖1项、省部级一等奖6余项，拥有已授权发明专利60余项，软件著作权20余项。</p>
      </div>
      <div style="height: 40px;"></div>
      <div class="introduce">
        <h1>企业文化</h1>
        <div style="height: 20px;"></div>
        <p>我们的<strong>使命</strong>是把数字智能带入每一个工业企业。</p>
        <p>我们的<strong>愿景</strong>是成为最值得信赖的卓越工厂数字化伙伴。</p>
        <p>我们的<strong>价值观</strong>是以客户为中心，以奋进者为本，专业主义、创新引领，诚信、敏捷、快乐、共赢。</p>
      </div>
    </div>

    <!-- 合作企业 -->
    <div class="cooperation">
      <h1>合作企业</h1>
      <div class="">
        <ul>
          <li v-for="item,index in cooperation_info" :key="index">
            <img :src="item.img_url" alt="" :width="item.width">
          </li>
        </ul>
      </div>
    </div>

    <!-- <div class="achievement-box">
      <div class="content">
        <div class="introduce">
          <div style="height: 60px"></div>
          <h1>成果介绍</h1>
          <div style="height: 20px"></div>
          <h3>国家奖励，发明专利，软件著作</h3>
          <div style="height: 10px"></div>
          <p>
            先后承担国家973、863、科技支持计划、重点研发计划等重要课题20多项。获国家科技进步二等奖3项、国家自然科学二等奖1项、省部级一等奖6余项，拥有已授权发明专利60余项，软件著作权20余项。
          </p>
          <div style="height: 30px"></div>
          <div class="images">
            <img src="@/assets/images/team/gjjl.png" alt="" />
            <img src="@/assets/images/team/fmzl.png" alt="" />
            <img src="@/assets/images/team/rjzz.png" alt="" />
          </div>
        </div>
        <div style="height: 100px"></div>
      </div>
    </div> -->

    <div class="course">
      <div class="course-content">
        <div style="height: 60px"></div>
        <h1>发展历程</h1>
        <div style="height: 30px"></div>
        <h3>数字化，网络化，智能化</h3>
        <div style="height: 20px"></div>
        <div class="development-history">
          <div class="stage" v-for="(stage, index) in stage_info" :key="index">
            <h3>{{ stage.name }}</h3>
            <span>{{ stage.year }}</span><br /><br />
            <span>{{ stage.desc }}</span><br /><br />
            <span>{{ stage.conclusion }}</span><br /><br />
            <span>{{ stage.achievement }}</span><br /><br />
            <span>{{ stage.awards }}</span><br /><br />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cooperation_info: [
        { img_url: require("@/assets/images/home/company/dongjiang.png"), width: '150px' },
        { img_url: require("@/assets/images/home/company/fangzheng.png"),  width: '180px' },
        { img_url: require("@/assets/images/home/company/meidi.png"),  width: '135px' },
        { img_url: require("@/assets/images/home/company/saihao.png"),  width: '200px' },
        { img_url: require("@/assets/images/home/company/yizumi.png"),  width: '130px' },
        { img_url: require("@/assets/images/home/company/sinopec.png"),  width: '150px' },
        { img_url: require("@/assets/images/home/company/hkws.png"),  width: '170px' },
      ],
      culture_info: [
        { title: '使命', desc: '把数字智能带入每一个工业企业' },
        { title: '愿景', desc: '最值得信赖的卓越工厂数字化伙伴' },
        { title: '价值观', desc: '以客户为中心，以奋进者为本，专业主义、创新引领，诚信、敏捷、快乐、共赢' },
      ],
      stage_info: [
        {
          name: "第一阶段",
          year: "1986年",
          desc: "在美国从事塑料注射成形模拟研究",
          conclusion: "",
          achievement: "开发了商品化冷却模拟软件",
          awards: "",
        },
        {
          name: "第二阶段",
          year: "1988年",
          desc: "中面模型的模拟",
          conclusion: "（从没有到拥有）",
          achievement: "基于中面的模拟软件",
          awards: "4项省部级科技进步奖",
        },
        {
          name: "第三阶段",
          year: "1996年",
          desc: "表面模型的模拟",
          conclusion: "（从中面到表面）",
          achievement: "基于表面的模拟软件",
          awards: "2002年国家科技进步二等奖",
        },
        {
          name: "第四阶段",
          year: "2002年",
          desc: "实体模型的模拟",
          conclusion: "（从宏观到介观）",
          achievement: "宏介观结合模拟软件",
          awards: "07、10年国家科技自然二等奖",
        },
        {
          name: "第五阶段",
          year: "2007年",
          desc: "成形装备的智能化",
          conclusion: "（从工艺到装备）",
          achievement: "智能型塑料注射机",
          awards: "2019年国家科技进步二等奖",
        },
        {
          name: "第六阶段",
          year: "2019年",
          desc: "从技术研究到应用推广",
          conclusion: "",
          achievement: "2020年成立武汉模鼎科技有限公司",
          awards: "",
        },
      ],
    }
  },
  mounted() {
    this.addListener();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScrollListener);
  },
  methods: {
    addListener() {
      this.handleScrollListener = function () {
        const history = document.querySelector(".development-history");
        const boxes = history.querySelectorAll(".stage");
        const rect = history.getBoundingClientRect();

        if (rect.top < 1120) {
          let delay = 0;
          boxes.forEach((box) => {
            setTimeout(() => {
              box.classList.add("show");
            }, delay);
            delay += 600;
          });
        }
      };
      window.addEventListener("scroll", this.handleScrollListener);
    },
  },
};
</script>

<style lang="less" scoped>
.bg-item {
  background: url(../../assets/images/home/fengjing1.jpg);
  background-size: cover;
  position: relative;
  height: 680px;
}
.bg-item::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  opacity: .9;
  clip-path: polygon(0 0, 89.5% 0, 75% 100%, 0 100%);
}
.about-us {
  width: 1200px;
  position: absolute;
  top:170px;
  left: 350px;
}
.cooperation {
  width: 1200px;
  margin: 90px auto;
  h1 {
    margin-bottom: 80px;
  }
  ul {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-content: space-around;
    li {
      width: 238px;
      height: 123px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid rgb(243, 243, 243);
    }
  }
}
.achievement-box {
  width: 100%;
  height: 770px;
  background: rgb(246, 246, 246);
  .introduce {
    .images {
      display: flex;
      justify-content: space-between;
    }
  }
}
.course {
  background-color: rgb(246, 246, 246);c
  // background: url('../../assets/images/home/company/aboutBg.jpg') no-repeat center top;
    .arrow-hr {
    border: none;
    height: 2px;
    background-color: rgb(246, 246, 246);
  }
  .course-content {
    width: 1260px;
    height: 650px;
    margin: 0 auto;
  }
  .arrow-hr::after {
    content: "";
    position: absolute;
    top: -9.5px;
    right: 3px;
    width: 20px;
    height: 20px;
    border-top: 2px solid white;
    border-right: 2px solid white;
    transform: rotate(45deg);
  }
  .development-history {
    display: flex;
    justify-content: space-between;
    .stage {
      position: relative;
      width: 130px;
      padding-left: 30px;
      border-left: 2px solid #ccc;
      span {
        color: #333;
      }
    }
  }
  h3,
  h1 {
    color: #333;
    padding-bottom: 20px;
  }
}
.development-history .stage:nth-child(1n) {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}
.development-history .stage.show {
  opacity: 1;
}
</style>